import React, { ReactNode } from 'react';
import './header.module.less';

const Header = (): ReactNode => {
  return (
    <div>
      <h1>Welcome to Header!</h1>
    </div>
  );
};

export { Header };
