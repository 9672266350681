export const COMPANY_NAME_PLACEHOLDER = 'Your company name';
export const INSTITUTION_SUB_DOMAIN_PLACEHOLDER = 'Sub-domain';
export const INSTITUTION_PHONE_NUMBER_PLACEHOLDER = 'Company phone number';
export const INSTITUTION_POSITION_PLACEHOLDER = 'Select your position';

export const WOS_FEATURE_PLACEHOLDER = 'Select feature';
export const WOS_NO_OF_EMPLOYEES_PLACEHOLDER = 'Number of employees';
export const WOS_NO_OF_ESTIMATED_APPLICATIONS_PLACEHOLDER =
  'Estimated number of applications';
export const WOS_NO_OF_DESTINATION_COUNTRIES_PLACEHOLDER =
  'Number of Destination countries';
export const WOS_COUNTRIES_OPERATED_PLACEHOLDER = 'Countries operated';

export const PASSWORD_PLACEHOLDER = 'Password';
export const CONFIRM_PASSWORD_PLACEHOLDER = 'Confirm password';
export const EMAIL_PLACEHOLDER = 'Enter Email';
export const FIRST_NAME_PLACEHOLDER = 'First name';
export const LAST_NAME_PLACEHOLDER = 'Last name';
export const DATE_OF_BIRTH_PLACEHOLDER = 'Date of Birth';
export const PASSPORT_EXPIRY_DATE_PLACEHOLDER = 'Select Passport Expiry Date';
export const VISA_EXPIRY_DATE_PLACEHOLDER = 'Select Visa Expiry Date';

export const DEGREE_lEVEL_PLACEHOLDER = 'Select Degree Level';
export const INSTITUTION_PLACEHOLDER = 'Institution';
export const COURSE_END_PLACEHOLDER = 'Select Course End Date';
export const COURSE_START_PLACEHOLDER = 'Select Course Start Date';
export const SUBJECT_AREA_PLACEHOLDER = 'Select Subject Area';
export const SUBJECT_PLACEHOLDER = 'Select Discipline';
export const SUBJECT_AREA_EXTRA = 'Must select discipline first.';

export const COUNTRY_PLACEHOLDER = 'Country';
export const CITY_OR_STATE_PLACEHOLDER = 'City/State';
export const PLACEHOLDER = {
  CATEGORY: 'Enter Category Name',
  STAGE_NAME: 'Enter Stage Name',
  FORM_NAME: 'Form Name',
  LABEL: 'Enter Label',
  OPTION: 'Enter Option',
  FORM_TITLE: 'Form Title',
  LABEL_TYPE: 'Select label type',
  CITY: 'Please enter your city',
  HEADER_TEXT: 'Header Text (e.g: Address / Contact Details)',
  PRIVACY_INFO: 'Please enter Privacy Information here.',
  CONSENT_INFO: 'Please enter Consent Information here.',
  COMMENTS: 'Please enter Comments here...',
  STATE: 'Please enter you state',
  CLIENT_ID: 'Please enter Client id',
  DATE: 'DD/MM/YYYY',
  TIME: 'Select Time',
  SELECT_CONTACT: 'Select Contact',
  APPLICATION: 'Select Application',
  SOURCE: 'Select Source',
  TAGS: 'Select TAGS',
  FOLLOWERS: 'Select Followers',
  SELECT_CATEGORY: 'Select Category',
  DEGREE_LEVEL: 'Select Degree Level',
  PRODUCT_TYPE: 'Select Product Type',
  PRODUCT_SUB_TYPE: 'Select Product Sub Type',
  INTAKES: 'Select Intakes',
  PASSPORT_NUMBER: 'Please Enter Your Passport Number',
  SEARCH_LEAD_FORMS: 'Enter search string here...',
  SELECT_BRANCH: 'Select Branch',
  SEARCH: 'Search...',
  SELECT_WORKFLOW: 'Select Workflow',
  SELECT_WORKFLOW_TYPE: 'Select Workflow Type',
  SELECT_SERVICE: 'Select Service',
  SELECT_INSTITUTION: 'Select Institution',
  SELECT_INSTALLMENT_TYPE: 'Select Installment Type',
  SELECT_FEE_TYPE: 'Select Fee Type',
  SELECT_PRODUCT: 'Select Product',
  SELECT_TIMEZONE: 'Select Timezone',
  SELECT_DEPARTMENT: 'Select Department',
  DEPARTMENT: 'Please Enter Department Name',
  DOCUMENT_TYPE: 'Document Type',
  JOB_TITLE: 'Please enter Job Title',
  INDUSTRY: 'Select Industry',
  EMPLOYMENT_TYPE: 'Select Employment Type',
  JOB_DESCRIPTION: 'Please Enter Job Description',
  ORGANIZATION_NAME: 'Please Enter Organization Name',
  START_DATE: 'Select Start Date',
  END_DATE: 'Select End Date',
  REFERENCE_NAME: 'Please Enter Reference Name',
  REFERENCE_POSITION: 'Please Enter Reference Position',
  REFERENCE_PHONE: 'Please Enter Reference Phone',
  REFERENCE_EMAIL: 'Please Enter Reference Email',
  ENTER_FORM_NAME: 'Please Enter Form Name',
  SELECT_PRIORITY: 'Please Select Priority',
  WORKFLOW: 'Enter Workflow Name',
  WORKFLOW_TYPE: 'Enter Workflow Type Name',
  ACTOR: 'Select Actor',
  MULTIPLE_CHECKLISTS: 'Select checklist item(s)',
  INTAKE_YEAR: 'Select Intake Year',
  INTAKE_MONTH: 'Select Intake Month',
};

export const PHONE_PLACEHOLDER = 'Phone Number';
export const ASSIGNEE_PLACEHOLDER = 'Select assignee';
export const ADDRESS1_PLACEHOLDER = '';
export const ADDRESS2_PLACEHOLDER = '';
export const ALTERNATIVE_PHONE_PLACEHOLDER = 'Alternative Phone number';
export const ROLE_PLACEHOLDER = 'Select Role';
export const OFFICE_PLACEHOLDER = 'Branch name';
export const TITLE_PLACEHOLDER = 'Title';
export const DESCRIPTION_PLACEHOLDER = 'Enter Description here...';
export const COMMENTS_PLACEHOLDER = 'Enter your comments here...';
export const SERVICE_NAME_PLACEHOLDER = 'Enter service name';

export const CITY_PLACEHOLDER = 'Enter City';
export const STATE_PLACEHOLDER = 'Enter State';
export const ZIP_CODE_PLACEHOLDER = 'Enter Zip Code';
export const NATIONALITY_PLACEHOLDER = 'Select Nationality';
export const TEST_SCORE_PLACEHOLDER = 'Select Test';
