import React, { Suspense } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import Cookies from 'js-cookie';
import { COOKIE_ACCESS } from '@moxie/constants';
import { Spin } from 'antd';

const LogProtectedRoute = ({
  component: Component,
  ...rest
}: RouteProps | any): any => {
  return (
    <Route
      {...rest}
      render={(props) =>
        Cookies.get(COOKIE_ACCESS) ? (
          <Redirect to="/dashboard" />
        ) : (
          <Suspense
            fallback={
              <div className="page-load">
                <Spin />
              </div>
            }
          >
            <Component {...props} />
          </Suspense>
        )
      }
    />
  );
};

export { LogProtectedRoute };
