import React from 'react';
import { Route } from 'react-router-dom';
import { CrmDashboard } from './dashboard';
import { CrmDashboardLayout } from './crm-dashboard-layout';
import UserAccountSettings from './dashboard-account-settings/account-settings';
import GeneralSettings from './dashboard-settings/general-settings';

const CrmDashboardRoute: React.FC = () => {
  return (
    <>
      <Route exact path="/dashboard" component={CrmDashboard} />
      <Route
        exact
        path="/dashboard/account-settings"
        component={UserAccountSettings}
      />
      <Route path="/dashboard/settings" component={GeneralSettings} />
    </>
  );
};

export { CrmDashboardRoute, CrmDashboardLayout };
