import React from 'react';
import { Row, Typography, Col } from 'antd';
import { PAGE_TITLE_SIZE } from '@moxie/constants';

const { Title } = Typography;

interface IPageHeaderTitle {
  title: string;
}

const PageHeaderTitle: React.FC<IPageHeaderTitle> = ({
  title,
}: IPageHeaderTitle) => {
  return (
    <Row>
      <Col span={24}>
        <Title level={PAGE_TITLE_SIZE}>
          <strong>{title}</strong>
        </Title>
      </Col>
    </Row>
  );
};

export { PageHeaderTitle };
