export const SLUG_RAW_LEADS = 'raw';
export const SLUG_ALL_LEADS = 'all';
export const SLUG_MY_LEADS = 'my-leads';
export const SLUG_ARCHIVED = 'archived';
export const SLUG_ASSIGNED_LEADS = 'assigned';
export const SLUG_PROFILE_ACTIVITIES = 'activities';
export const SLUG_CLIENTS = 'clients';
export const SLUG_INSTITUTIONS = 'institutions';
export const SLUG_DESCRIPTION = 'description';
export const SLUG_PRODUCTS = 'products';
export const SLUG_LEADS = 'leads';
export const SLUG_ALL_CONTACTS = 'all-contacts';
export const SLUG_ASSIGNED_CONTACTS = 'assigned-contacts';
export const SLUG_MY_CONTACTS = 'my-contacts';
export const SLUG_ALL_PROSPECTS = 'all-prospects';
export const SLUG_ALL_CLIENTS = 'all-clients';
export const SLUG_MY_CLIENTS = 'my-clients';
export const SLUG_MY_PROSPECTS = 'my-prospects';
export const SLUG_ALL_TASKS = 'all';
export const SLUG_MY_TASKS = 'my';

export const SLUG_KPI = 'kpi';
export const SLUG_PROFILE_APPLICATIONS = 'applications';
export const SLUG_PROFILE_DOCUMENTS = 'documents';
export const SLUG_PROFILE_NOTES = 'notes and terms';
export const SLUG_PROFILE_APPOINTMENTS = 'appointments';
export const SLUG_PROFILE_FEES = 'fees';
export const SLUG_EDUCATION_BACKGROUND = 'education-background';
export const SLUG_ACTIVITIES = 'activities';
export const SLUG_INTERESTED_SERVICES = 'interested-services';
export const SLUG_PROFILE_SECTION = 'profile-section';
export const SLUG_OTHER_DETAILS = 'other-details';
export const SLUG_APPLICATIONS = 'applications';
export const SLUG_DOCUMENTS = 'documents';
export const SLUG_NOTES = 'notes';
export const SLUG_ACTIVE = 'active';
export const SLUG_INACTIVE = 'inactive';
export const SLUG_DEPARTMENTS = 'departments';
export const SLUG_USERS = 'users';
export const SLUG_PROFESSIONAL_EXPERIENCE = 'professional-experiences';
export const SLUG_PRODUCT_COMPARISON = 'product-comparison';
export const SLUG_WAITING = 'waiting';
export const SLUG_ATTENDING = 'attending';
export const SLUG_COMPLETED = 'completed';
export const SLUG_PERSONAL = 'personal';
export const SLUG_FINANCIAL = 'financial';
export const SLUG_ACADEMIC = 'academic';
export const SLUG_INSURANCE = 'insurance';
export const SLUG_LANGUAGE = 'language';
export const SLUG_OFFICIAL_RESPONSES = 'official-responses';
export const SLUG_OTHER_CERTIFICATES = 'other-certificates';
export const SLUG_OFFICE_VISITS = 'office-visits';
export const SLUG_ENQUIRIES = "enquiries";
