import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import Cookies from 'js-cookie';
import { COOKIE_ACCESS } from '@moxie/constants';
import { SessionRefreshModal } from '@shared-components/modules';

const ProtectedRouteNoLayout = ({
  component: Component,
  ...rest
}: RouteProps | any): any => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !Cookies.get(`${COOKIE_ACCESS}`) ? (
          <Redirect to="/" />
        ) : (
          <>
            <SessionRefreshModal />
            <Component {...props} />
          </>
        )
      }
    />
  );
};

export { ProtectedRouteNoLayout };
