import {
  SLUG_ACADEMIC,
  SLUG_ACTIVE,
  SLUG_ACTIVITIES,
  SLUG_ALL_CLIENTS,
  SLUG_ALL_CONTACTS,
  SLUG_ALL_LEADS,
  SLUG_ALL_PROSPECTS,
  SLUG_APPLICATIONS,
  SLUG_ARCHIVED,
  // SLUG_ASSIGNED_LEADS,
  SLUG_ATTENDING,
  SLUG_COMPLETED,
  SLUG_DEPARTMENTS,
  SLUG_DOCUMENTS,
  SLUG_FINANCIAL,
  SLUG_INACTIVE,
  SLUG_INSURANCE,
  SLUG_INTERESTED_SERVICES,
  SLUG_LANGUAGE,
  SLUG_MY_CLIENTS,
  SLUG_MY_CONTACTS,
  SLUG_MY_LEADS,
  SLUG_MY_PROSPECTS,
  SLUG_NOTES,
  SLUG_OFFICIAL_RESPONSES,
  SLUG_OTHER_CERTIFICATES,
  SLUG_PERSONAL,
  SLUG_PROFILE_SECTION,
  // SLUG_RAW_LEADS,
  SLUG_USERS,
  SLUG_WAITING,
} from './slug';

export const PAGE_HEADER_MENU_LEAD = [
  {
    name: 'All Leads',
    id: SLUG_ALL_LEADS,
    url: `leads/${SLUG_ALL_LEADS}`,
  },
  {
    name: 'My Leads',
    id: SLUG_MY_LEADS,
    url: `leads/${SLUG_MY_LEADS}`,
  },
  {
    name: 'Archived',
    id: SLUG_ARCHIVED,
    url: `leads/${SLUG_ARCHIVED}`,
  },
  // {
  //   name: 'Raw Leads',
  //   id: SLUG_RAW_LEADS,
  //   url: `leads/${SLUG_RAW_LEADS}`,
  // },
  // {
  //   name: 'Assigned Leads',
  //   id: SLUG_ASSIGNED_LEADS,
  //   url: `leads/${SLUG_ASSIGNED_LEADS}`,
  // },
];

export const PAGE_HEADER_MENU_CLIENT = [
  {
    name: 'All Clients',
    id: SLUG_ALL_CLIENTS,
    url: `clients/${SLUG_ALL_CLIENTS}`,
  },
  {
    name: 'My Clients',
    id: SLUG_MY_CLIENTS,
    url: `clients/${SLUG_MY_CLIENTS}`,
  },
];
export const PAGE_HEADER_MENU_PROSPECT = [
  {
    name: 'All Prospects',
    id: SLUG_ALL_PROSPECTS,
    url: `prospects/${SLUG_ALL_PROSPECTS}`,
  },
  {
    name: 'My Prospects',
    id: SLUG_MY_PROSPECTS,
    url: `prospects/${SLUG_MY_PROSPECTS}`,
  },
  {
    name: 'Archived',
    id: SLUG_ARCHIVED,
    url: `prospects/${SLUG_ARCHIVED}`,
  },
];

export const PAGE_HEADER_MENU_CONTACT = [
  {
    name: 'All Contacts',
    id: SLUG_ALL_CONTACTS,
    url: `contacts/${SLUG_ALL_CONTACTS}`,
  },
  {
    name: 'My Contacts',
    id: SLUG_MY_CONTACTS,
    url: `contacts/${SLUG_MY_CONTACTS}`,
  },
  {
    name: 'Archived',
    id: SLUG_ARCHIVED,
    url: `contacts/${SLUG_ARCHIVED}`,
  },
];

export const PAGE_HEADER_MENU_TASKS = [
  {
    name: 'All ',
    id: 'all',
    url: `tasks/all`,
  },
  {
    name: 'Assigned to Me',
    id: 'my',
    url: `tasks/my`,
  },
];

export const PAGE_HEADER_MENU_INSTITUTE = [
  {
    name: 'Institutions',
    id: 'institutions',
  },
];

export const PAGE_HEADER_MENU_PREFRENCE = [
  {
    name: 'Preferences',
    id: 'preferences',
  },
];

export const PAGE_HEADER_MENU_CHANGE_PASSWORD = [
  {
    name: 'Change Password',
    id: 'change-password',
  },
];

export const PAGE_HEADER_MENU_OFFICE_BRANCH = [
  {
    name: 'Branches',
    id: 'branches',
  },
];

export const PAGE_HEADER_MENU_SERVICES = [
  {
    name: 'Services',
    id: 'services',
  },
];

export const PAGE_HEADER_MENU_WORKFLOW_TYPES = [
  {
    name: 'Workflow Types',
    id: 'workflow-type',
  },
];
export const PAGE_HEADER_MENU_WORKFLOWS = [
  {
    name: 'Workflows',
    id: 'workflows',
  },
];

export const PAGE_HEADER_MENU_DEPARTMENT_BRANCH = [
  {
    name: 'Departments',
    id: 'departments',
  },
];

export const PAGE_HEADER_MENU_PRODUCTS = [
  {
    name: 'Products',
    id: 'products',
  },
];

export const PAGE_HEADER_MENU_TENANTS = [
  {
    name: 'Tenants',
    id: 'tenants',
  },
];

export const PAGE_HEADER_MENU_CLIENTS = [
  {
    name: 'Clients',
    id: 'clients',
  },
];
export const PAGE_HEADER_MENU_ROLE = [
  {
    name: 'Roles',
    id: 'roles',
  },
];

export const PAGE_HEADER_MENU_LEADS_FORM = [
  {
    name: 'Active',
    id: 'active',
    url: `lead-forms/${SLUG_ACTIVE}`,
  },
  {
    name: 'Inactive',
    id: 'inactive',
    url: `lead-forms/${SLUG_INACTIVE}`,
  },
];

export const PAGE_HEADER_MENU_USER = [
  {
    name: 'Active',
    id: 'active',
  },
];

export const PAGE_HEADER_MENU_CRM_USER = [
  {
    name: 'Active',
    id: 'active',
    url: `users/${SLUG_ACTIVE}`,
  },
  {
    name: 'Inactive',
    id: 'inactive',
    url: `users/${SLUG_INACTIVE}`,
  },
];

export const PAGE_HEADER_MENU_OFFICE_DETAIL = [
  {
    name: 'Departments',
    id: SLUG_DEPARTMENTS,
    url: `${SLUG_DEPARTMENTS}`,
  },
  {
    name: 'Users',
    id: SLUG_USERS,
    url: `${SLUG_USERS}`,
  },
];

export const PAGE_HEADER_MENU_LEAD_DETAIL = [
  {
    name: 'Activities',
    id: SLUG_ACTIVITIES,
    url: `${SLUG_ACTIVITIES}`,
  },
  {
    name: 'Profile',
    id: SLUG_PROFILE_SECTION,
    url: `${SLUG_PROFILE_SECTION}`,
  },
  {
    name: 'Applications',
    id: SLUG_APPLICATIONS,
    url: `${SLUG_APPLICATIONS}`,
  },
  {
    name: 'Documents',
    id: SLUG_DOCUMENTS,
    url: `${SLUG_DOCUMENTS}`,
  },
];

export const PAGE_HEADER_MENU_DEPARTMENT_DETAIL = [
  {
    name: 'Users',
    id: 'users',
  },
];

export const PAGE_HEADER_MENU_INSTITUTION_DETAIL = [
  {
    name: 'Branches',
    id: 'branches',
    url: 'branches',
  },
  {
    name: 'Products',
    id: 'products',
    url: 'products',
  },
  { name: 'Applications', id: 'applications', url: 'applications' },
];

export const PAGE_HEADER_MENU_ADMIN_INSTITUTION_DETAIL = [
  {
    name: 'Branches',
    id: 'branches',
    url: 'branches',
  },
  {
    name: 'Products',
    id: 'products',
    url: 'products',
  },
];

export const PAGE_HEADER_MENU_APPLICATION_DETAIL = [
  { name: 'Documents', id: 'documents' },
  // { name: 'Forms', id: 'forms' }, Commented for future use
];

export const OTHER_DETAILS_MENU = [
  {
    name: 'Form Fields',
    id: 'Category',
  },
];

export const DOCUMENT_CHECKLIST_MENU = [
  {
    name: 'Document Checklist',
    id: 'Document Checklist',
  },
];

export const PAGE_HEADER_MENU_OFFICE_VISIT = [
  {
    name: 'Waiting',
    id: SLUG_WAITING,
    url: `office-visit/${SLUG_WAITING}`,
  },
  {
    name: 'Attending',
    id: SLUG_ATTENDING,
    url: `office-visit/${SLUG_ATTENDING}`,
  },
  {
    name: 'Completed',
    id: SLUG_COMPLETED,
    url: `office-visit/${SLUG_COMPLETED}`,
  },
  {
    name: 'Archived',
    id: SLUG_ARCHIVED,
    url: `office-visit/${SLUG_ARCHIVED}`,
  },
];

export const PAGE_HEADER_MENU_CONTACT_DETAIL = [
  {
    name: 'Activities',
    id: SLUG_ACTIVITIES,
    url: `${SLUG_ACTIVITIES}`,
  },
  {
    name: 'Applications',
    id: SLUG_APPLICATIONS,
    url: `${SLUG_APPLICATIONS}`,
  },
  {
    name: 'Interested Services',
    id: SLUG_INTERESTED_SERVICES,
    url: `${SLUG_INTERESTED_SERVICES}`,
  },
  {
    name: 'Documents',
    id: SLUG_DOCUMENTS,
    url: `${SLUG_DOCUMENTS}`,
  },
  {
    name: 'Notes',
    id: SLUG_NOTES,
    url: `${SLUG_NOTES}`,
  },
];

export const DOCUMENT_TYPE_DETAIL = [
  {
    name: 'Personal',
    id: SLUG_PERSONAL,
    url: `${SLUG_PERSONAL}`,
  },
  {
    name: 'Language',
    id: SLUG_LANGUAGE,
    url: `${SLUG_LANGUAGE}`,
  },
  {
    name: 'Insurance',
    id: SLUG_INSURANCE,
    url: `${SLUG_INSURANCE}`,
  },
  {
    name: 'Financial',
    id: SLUG_FINANCIAL,
    url: `${SLUG_FINANCIAL}`,
  },
  {
    name: 'Academic',
    id: SLUG_ACADEMIC,
    url: `${SLUG_ACADEMIC}`,
  },
  {
    name: 'Official Responses',
    id: SLUG_OFFICIAL_RESPONSES,
    url: `${SLUG_OFFICIAL_RESPONSES}`,
  },
  {
    name: 'Other Certificates',
    id: SLUG_OTHER_CERTIFICATES,
    url: `${SLUG_OTHER_CERTIFICATES}`,
  },
];
