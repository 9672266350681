import { COLUMNS, LABEL, URL_PRODUCT_DETAIL_LINK } from '@moxie/constants';
import { Table, UserNameCard } from '@shared-components/elements';
import {
  IActiveStage,
  ILead,
  IProduct,
  IWorkflow,
} from '@shared-components/models';
import { Typography } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ApplicationAssignee } from '../../lead/application/application-assignee';
const InstitutionApplicationsTable: React.FC<any> = ({
  getData,
}: {
  getData: any;
}) => {
  const [refreshTable, setRefreshTable] = useState(false);
  const column = [
    {
      title: LABEL.APPLICATION_ID,
      dataIndex: 'id',
      width: 150,

      sorter: () => 0,
      render(id: string, data: any) {
        return (
          <Link to={`/contact/detail/${data?.client?.id}/applications/${id}`}>
            <Typography.Text className="text-primary-color">
              {data?.company?.company_code + '-' + data?.app_identifier}
            </Typography.Text>
          </Link>
        );
      },
    },
    {
      title: LABEL.PRODUCT,
      width: 250,
      dataIndex: 'product',
      sorter: () => 0,
      render(data: IProduct) {
        return (
          <Link to={`${URL_PRODUCT_DETAIL_LINK}${data.id}/applications`}>
            <Typography.Text className="text-primary-color">
              {data.name}
            </Typography.Text>
          </Link>
        );
      },
    },
    {
      title: LABEL.WORKFLOW,
      width: 150,
      dataIndex: 'workflow',
      render(data: IWorkflow) {
        return data?.workflowType?.name;
      },
    },
    {
      title: COLUMNS.AGENT_ASSIGNEE,
      width: 300,
      dataIndex: 'agent_clients',
      render(_text: any, data: any): React.ReactElement | any {
        return (
          <ApplicationAssignee
            data={data}
            refreshTable={refreshTable}
            setRefreshTable={setRefreshTable}
          />
        );
      },
    },

    {
      title: COLUMNS.CONTACT_NAME,
      dataIndex: 'client',
      width: 300,
      render(data: ILead): React.ReactElement {
        return (
          <UserNameCard
            firstName={data?.first_name || ''}
            lastName={data?.last_name || ''}
            email={data?.email || ''}
            link={`/contact/detail/${data?.id}/applications`}
          />
        );
      },
    },
    {
      title: LABEL.CURRENT_STAGE,
      dataIndex: 'activeStage',
      width: 200,
      render(data: IActiveStage) {
        return data?.stage;
      },
    },
  ];
  return (
    <div className="margin-top-1">
      <Table
        columns={column}
        refresh={refreshTable}
        filter={true}
        action={getData}
      />
    </div>
  );
};

export default InstitutionApplicationsTable;
