//App constants
export const APP_NAME = 'Agent ApplyIMS';
export const APP_SHORT_DESC =
  '#1 business management platform for education and migration agencies';
export const APP_VERSION = '1.0';

//Auth constants
export const REMEMBER_ME_COOKIE_EXPIRY_DATE = 30;
export const JWT_TOKEN_HEADER = 'Bearer';
export const COOKIE_ACCESS = 'access';
export const COOKIE_REFRESH = 'refresh';
export const ADMIN_COOKIE_ACCESS = 'adminAccess';
export const ADMIN_COOKIE_REFRESH = 'adminRefresh';
export const CRM_COOKIE_ACCESS = 'crmAccess';
export const CRM_COOKIE_REFRESH = 'crmRefresh';
export const COOKIE_EXPIRY = 7;
export const NO_TOKEN_MESSAGE = 'No token!';

//Dashboard Footer constant
export const DASHBOARD_FOOTER = `${new Date().getFullYear()} ${APP_NAME}, All Rights Reserved.`;

export const ADD_NEW_ROLE_LABEL = 'Role Name';
export const PERMISSIONS_LABEL = 'Permissions';
export const PERMISSIONS_OFFICES_TEAMS = 'Offices & Teams';
export const PERMISSIONS_ROLES = 'Roles Permissions';
export const PERMISSIONS_USERS = 'Users Permissions';
export const PERMISSIONS_CLIENTS = 'Clients Permissions';
export const PERMISSIONS_APPLICATIONS = 'Applications Permissions';
export const PERMISSIONS_PRODUCTS = 'Products Permissions';
export const PERMISSIONS_INSTITUTIONS = 'Institutions Permissions';
export const PERMISSIONS_WORKFLOWS = 'Workflows Permissions';
export const PERMISSIONS_WORKFLOWTYPES = 'Workflow Types Permissions';
export const PERMISSIONS_DEPARTMENTS = 'Departments Permissions';
export const ROLE_NAME_REQUIRED = 'Please add role name.';
export const ROLE_DESCRIPTION_REQUIRED = 'Please add role description.';

//Button constants
export const SAVE_BTN = 'Save';
export const SUBMIT_BTN = 'Submit';
export const ADD_BTN = 'Add';
export const ADD_SERVICE_BTN = 'Add Service';
export const ADD_APPOINTMENT_BTN = 'Add Appointment';
export const ADD_APPLICATION_BTN = 'Add Application';
export const START_BTN = 'Start';
export const CLEAR_BTN = 'Clear';
export const SEARCH_PRODUCTS_BTN = 'Search Products';
export const ADD_DOCUMENT = 'Add Document';
export const DOWNLOAD_ALL = 'Download All';
export const PREPARING_FILES = 'Preparing Files';
export const REMOVE_FROM_COMPARISON = 'Remove from comparison';
export const ADD_TO_INTERESTED_SERVICE = 'Add to interested services';
export const START_APPLICATION = 'Start Application';
export const ADD_FEES = 'Add Fees';
export const UPDATE_BTN = 'Update';
export const CANCEL_BTN = 'Cancel';
export const NOT_AVAILABLE_MESSAGE = 'N/A';
export const HYPHEN = '-';
export const APPLY_FILTERS_BTN = 'Apply Filters';
export const CLOSE_BTN = 'Close';
export const DONE_BTN = 'Done';
export const LOGIN_BTN = 'Log-in';
export const LOGOUT_BTN = 'Logout';
export const EDIT_BTN = 'Edit';
export const DEACTIVATE_BTN = 'Deactivate';
export const ACTIVATE_BTN = 'Activate';
export const DELETE_BTN = 'Delete';
export const DISCONTINUE_BTN = 'Discontinue';
export const REVERT_BTN = 'Revert';
export const SURE_CANCEL = 'Sure to cancel?';
export const SURE_DELETE = 'Sure to delete?';
export const DOWNLOAD_BTN = 'Download Template';
export const UPLOAD_BTN = 'Upload a File';
export const MANAGE_FORM = 'Manage Contact Forms';
export const MANAGE_ENQUIRY_FORMS = 'Manage Enquiry Forms';
export const ADD_ENQUIRY_FORM = 'Add Enquiry Form';
export const MANAGE_WORKFLOW_TYPES = 'Manage Workflow Types';
export const ADD_NEW_ROLE_BTN = 'New Role';
export const RENAME = 'Rename';
export const DOWNLOAD = 'Download';
export const PREVIEW = 'Preview';
export const VIEW_BTN = 'View';
export const COMPARE_BTN = 'Compare';
export const SET_MAIN_BRANCH = 'Make main branch';
export const VIEW_DETAILS = 'View Details';

export const VERIFICATION_BTN_SEND_VERIFICATION =
  'Send verification email again';
export const VERIFICATION_BTN_SEND_VERIFICATION_TOKEN = 'Send';
export const ASSIGN_TO_BTN = 'Click here to assign';
export const EDIT_ASSIGNEE = 'Edit Assignee';
export const BUTTON = {
  SAVED_FILTERS: 'Saved Filters',
  ADD_NEW_FILTER: 'Add New Filter',
  EDIT: 'Edit',
  ADD_FIELD: 'Add Field',
  ADD_CATEGORY: 'Add Category',
  PREVIEW: 'Preview',
  ADD_OPTION: 'Add Option',
  UPDATE_OPTION: 'Update Option',
  ADD_LABEL: 'Add Label',
  MANAGE_STAGES: 'Manage Stage',
  ADD_STAGES: 'Add Stage',
};

//Title constants
export const ADD_INSTITUTION = 'Add Institution';
export const IMPORT_INSTITUTION = 'Import Institution';
export const BACK_TO_INSTIUTION = 'Back to Institution';
export const ADD_LEAD = 'Add Lead';
export const ADD_CONTACTS = 'Add Contact';
export const CONTACTS = 'Contacts';
export const ADD_PRODUCT = 'Add Product';
export const CREATE_PRODUCT = 'Create a new Product';
export const VIEW_PRODUCT_TITLE = 'View Product';
export const EDIT_PRODUCT = 'Edit Product';
export const CREATE_TASKS = 'Create a new Task';
export const VIEW_TASK_TITLE = 'View Task';
export const EDIT_TASK = 'Edit Task';
export const LANGUAGE = 'Language';
export const ADD_NOTE = 'Add Note';
export const ADD_NEW_ROLE = 'Add New Role';
export const EDIT_ROLE = 'Edit Role';
export const VIEW_ROLE = 'View Role';
export const LIST_OF_ROLES = 'List of Roles';
export const CREATE_LEAD_TITLE = 'Create a new Contact';
export const CREATE_NEW_LEAD = 'Create a new Lead';
export const EDIT_LEAD_TITLE = 'Edit';
export const VIEW_LEAD_TITLE = 'View Lead';
export const VIEW_CLIENT_TITLE = 'View Client';
export const VIEW_CONTACT_TITLE = 'View Contact';
export const ADD_EDUCATION_TITLE = 'Add Education Background';
export const EDIT_EDUCATION_TITLE = 'Edit Education Background';
export const ADD_PROFESSIONAL_EXPERIENCE_TITLE = 'Add Professional Experience';
export const EDIT_PROFESSIONAL_EXPERIENCE_TITLE =
  'Edit Professional Experience';
export const EDIT_LANGUAGE_TEST_SCORE = 'Edit Language Test Score ';
export const EDIT_OTHER_TEST_SCORE = 'Edit Other Test Score ';
export const ADD_INTERESTED_SERVICE = 'Add Interested Service';
export const EDIT_INTERESTED_SERVICE = 'Edit Interested Service';
export const ADD_OTHER_TEST_SCORE = 'Add Other Test Score';
export const ADD_LANGUAGE_TEST_SCORE = 'Add Language Test Score ';
export const CREATE_NOTE_TITLE = 'Create a new Note';
export const VIEW_NOTE_TITLE = ' View Note';
export const EDIT_NOTE_TITLE = 'Edit Note';
export const EDIT = 'Edit';
export const NOTES_TITLE = 'Notes';
export const AUTH_TITLE = 'Reset your password';
export const PLEASE_SET_YOUR_PASSWORD = 'Please set your password';
export const CHECK_MAIL_TITLE = 'Check your mail';
export const VERIFICATION_TITLE = 'Email verification';
export const LEAD_TITLE = 'Leads';
export const LEAD = 'Lead';
export const APPOINTMENT_TITLE = 'Appointments';
export const TASKS_TITLE = 'Tasks';
export const TENANTS_TITLE = 'Tenants';
export const TENANT = 'Tenant';
export const ADD_TENANT = 'Add Tenant';
export const APPLICATION_SETTINGS = 'Application Settings';

export const PREFERENCE_TITLE = 'Preferences';
export const OTHER_DETAILS = 'Other Details';
export const INSTITUTE_TITLE = 'Institutions';
export const IMPORT_INSTITUTIONS = 'Import Institutions';
export const APPLICATION_TITLE = 'Applications';
export const PRODUCT_TITLE = 'Products';
export const WORKFLOW_TITLE = 'Workflows';
export const ADD_WORKFLOW_TYPE = 'Add Workflow type';
export const ADD_WORKFLOW = 'Add Workflow';
export const USER_TITLE = 'Users';
export const ORGANIZATION_TITLE = 'Organization';
export const OFFICE_TITLE = 'Offices';
export const SERVICES_TITLE = 'Services';
export const USER_ADD_TITLE = 'Add User';
export const USER_EDIT_TITLE = 'Edit User';
export const PROFILE_EDIT_TITLE = 'Update Profile';
export const DEPARTMENT_TITLE = 'Departments';
export const DEPARTMENT_DETAIL_TITLE = 'Department Detail';
export const REPORT_TITLE = 'Reports';
export const CONTACTS_REPORT_TITLE = 'Contacts Report';
export const APPLICATION_REPORT_TITLE = 'Application Reports';

export const LEAD_IMPORT_TITLE = 'Import Enquiries';
export const LEAD_DATA_TITLE =
  'Add a row for each enquiries in your company with a column for each file.';
export const USER_PERSONAL_DETAIL = 'Personal Information';
export const CONTACT_INFORMATION = 'Contact Information';
export const JOB_INFORMATION = 'Job Information';
export const CHANGE_PASSWORD = 'Change Password';
export const CHANGE_TIMEZONE = 'Set your Timezone';
export const MAKE_FAVORITE = 'Add as Favorite';
export const REMOVE_FAVORITE = 'Remove from Favorite';
export const MAKE_ACTIVE = 'Make Active';
export const MAKE_INACTIVE = 'Make Inactive';

export const CREATE_OFFICE_TITLE = 'Create new Office';
export const EDIT_OFFICE_TITLE = 'Edit Office';

export const CREATE_WORKFLOW_TYPE_TITLE = 'Create new Workflow Type';
export const EDIT_WORKFLOW_TYPE_TITLE = 'Edit Workflow Type';

export const CREATE_WORKFLOW_TITLE = 'Create new Workflow';
export const EDIT_WORKFLOW_TITLE = 'Edit Workflow';

export const CREATE_DEPARTMENT_TITLE = 'Create new Department';
export const EDIT_DEPARTMENT_TITLE = 'Edit Department';
export const COMING_SOON = 'Coming Soon';

export const TITLE = {
  CREATE_APPLICATION: 'Create Application',
  LEAD_FORM: 'Contact Forms',
  SELECT_PRODUCT_WORKFLOW: 'Select Product Workflow',
  PRODUCT_COMPARISON: 'Product comparison',
  ADD_OPTION: 'Add an Option',
  UPDATE_OPTION: 'Update an Option',
  CATEGORIES: 'Categories',
  ADD_COVER_IMAGE: 'Add Cover Image',
  DRAG_TO_UPLOAD: 'Click or drag file to this area to upload',
  UPLOAD_SINGLE_IMAGE: 'Upload only single Image',
  UPLOAD_LOGO: 'Upload Logo',
  ADD_DISPLAY_IMAGE: 'Add Display Image',
  SAVE_FORM_AS: 'Save Form As',
  FORM_HEADER: 'Form Header',
  PRODUCT_SEARCH: 'Product Search',
  PRODUCT_COMPARE: 'Product Compare',
  CHOOSE_FORM_FIELDS: 'Choose Form Fields',
  GDPR_COMPLIANCE: 'Legal & GDPR Compliance',
  PERSONAL_DETAILS: 'Personal Details',
  CONTACT_DETAILS: 'Contact Details',
  ADDRESS_DETAILS: 'Address Details',
  VISA_INFO_DETAILS: 'Visa Information Details',
  INTERESTED_SERVICES: 'Interested Services',
  EDUCATION_BACKGROUND: 'Education Background',
  ENGLISH_TEST_SCORES: 'English Test Scores',
  OTHER_TEST_SCORES: 'Other Test Scores',
  OTHER_DETAILS: 'Other Details',
  LEADS_FORM_LISTS: 'Leads form Lists',
  SERVICE_PREFERENCE: 'Service Preferences',
  GENERAL_DETAILS: 'General Details',
  QUALIFICATIONS: 'Qualifications',
  PROFESSIONAL_EXPERIENCE: 'Professional Experience',
  IELTS: 'IELTS',
  PTE: 'PTE',
  TOEFL: 'TOEFL',
  PRIVACY_INFORMATION: 'Privacy Information',
  ADD_PRODUCT_FEE_TITLE: 'Add Product Fee',
  EDIT_PRODUCT_FEE_TITLE: 'Edit Product Fee',
  PRODUCT_FEE_SUCCESS: 'Successfully added Product Fee.',
  WARNING: 'Warning',
  CONTACT: 'Contact',
  PROSPECT: 'Prospect',
  CLIENT: 'Client',
  EDIT: 'Edit',
  ACTIVITY_LOG_DRAWER_TITLE: 'Activity Log',
  ALL_NOTIFICATION: 'All Notifications',
  OFFICE_VISIT: 'Office Visit',
};
export const LEAD_FORM_ADD_TITLE = 'Create new Contact Form';
export const LEAD_FORM_EDIT_TITLE = 'Edit Contact Form';

//drawer constants
export const USER_ADD_PLACEMENT = 'right';
export const DRAWER_WIDTH = 720;

//constants
export const IMPORT = 'Import';
export const REQUIRED = 'Required';
export const OPTIONAL = 'Optional';
export const TYPE = 'Type:';
export const SIZE = 'Size:';
export const PREFERRED = 'Preferred:';
export const XLSX = 'xlsx,xls';
export const LIMIT = 'less than 10 MB';
export const SUCCESS = 'Success';
export const ADDITIONAL_INFORMATION = 'Add additional Information';
export const PRODUCT_DETAILS = 'Product Details';
export const PRODUCT_INFORMATION = 'Product Information';
export const OFFICE_INFORMATION = 'Office Information';
export const DEPARTMENT_INFORMATION = 'Department Information';
export const CURRENT_VISA_INFORMATION = 'Current Visa Information';
export const ADDRESS_INFORMATION = 'Address Information';
export const ADDRESS = 'Address';
export const MAIN_BRANCH = 'Main Branch';
export const BRANCH = 'Branch';
export const COMPANY = 'Company';
export const ADDITIONAL_PERSONAL_INFORMATION =
  'Additional Personal Information';
export const WORKFLOW_TYPE_INFORMATION = 'Workflow Type Information';
export const WORKFLOW_INFORMATION = 'Workflow Information';
export const INTAKE = 'Intake';
export const INDICATIVE_FEE = 'Indicative Fee';
export const IELTS_SCORE = 'IELTS Score';
export const SHOW_FILTERS = 'Show Filters';
export const IMPORT_CONTACT = 'Import Contact';

export const INTERNAL_INFORMATION = 'Internal Information';

//Description constants
export const FORGET_PASSWORD_DESCRIPTION =
  'Enter your email address and we will share a link to create a new password';
export const CHECKMAIL_DESCRIPTION =
  "We have sent a password recover instruction to your email. If you didn't receive your mail, check your spam filter";
export const DOWNLOAD_TEMPLATE = 'Download our sample file template.';
export const ENQ_INFO = 'Enter information for each enquiries.';
export const UPLOAD_FILE = 'Upload the completed spreadsheet file.';

//form
export const GUTTER = 16;
export const FORM_SIZE = 'middle';
export const BACK_TO_SIGN_IN = 'Back to Sign In';
export const DEFAULT_COUNTRY_CODE = '+977';
export const DEFAULT_COUNTRY = 'Nepal';
export const SEARCH = 'Search';
export const SEARCH_CONTACT = 'Search Contact';

//Table
export const ASCEND = 'ascend';
export const SERVER_ASCEND = 'ASC';
export const SERVER_DESCEND = 'DESC';
export const DESCEND = 'descend';

export const SEND_MAIL = 'Send Mail';
export const SEND_SMS = 'Send Sms';
export const ASSIGN = 'Assign';
export const ARCHIVE = 'Archive';
export const RESTORE = 'Restore';
export const UNSELECT = 'Unselect';
export const CONTACT = 'Contact';
export const ALL = 'All';
export const SELECT_ALL = 'Select all';
export const FROM_ALL_ENTRIES = 'from all entries';
export const ITEMS_SELECTED = 'item(s) are selected';
export const DEACTIVATE = 'Deactivate';
export const ACTIVATE = 'Activate';

//Lead Form Compliance
export const PRIVACY_INFO_TEXT =
  "We're committed to your privacy. We use the information you provide to us to contact you about our relevant content, products, and services. You may unsubscribe from these communications at any time by emailing us. For more information, check out our Privacy policy.";
export const CONSENT_TEXT = 'I agree to Terms of Service and Privacy Policy.';

export const CHECKLIST_DETAIL =
  'The changes made to documents in the checklist will only affect the current application';

export const COMPLETION_DETAIL =
  'The application of the current client has successfully been completed';

export const APPLICATION_FORM_DETAIL =
  "The information entered below will be saved to contact's profile";
export const COMPANY_FORM_RESPONSE = {
  COMPANY_NAME_REQ: 'Please input company name',
  COMPANY_EMAIL_REQ: 'Please input company email',
  PREFERRED: 'Preferred',
  COMPANY_PROFILE: 'Company Profile',
  PREFERRED_UPLOAD_LIST_TYPE: 'Type: png,jpeg,jpg',
  PREFERRED_UPLOAD_LIST_RESOLUTION: 'Resolution: 200 * 40',
  PREFERRED_UPLOAD_LIST_SIZE: 'Size: less than 2mb',
  COMPANY_NAME_NO_SPACE_IN_BEGINNING: 'Please input valid company name',
};

export const ADD_OFFICE_VISIT = 'Add Office Visit';
export const VISIT_DETAILS = 'Visit Details';
export const VISIT_PURPOSE = 'Visit Purpose';
export const EDIT_OFFICE_VISIT = 'Edit Office Visit';
export const ACTIVITY_LOG = 'Activity Log';

export const ADD_PARTNER_AGENT = 'Add Partner Agent';
export const EDIT_PARTNER_AGENT = 'Edit Partner Agent';

export const APP_REDIRECT_URI = 'app_url';
export const USER_ID = 'user_id';

export const ENQUIRY_FORM = {
  CREATE_NEW_ENQUIRY_FORM : 'Create New Enquiry Form',
  UPDATE_ENQUIRY_FORM : 'Edit Enquiry Form',
  BASIC_INFORMATION : 'Basic Information',
  PERSONAL_INFORMATION: 'Personal Information',
  ADDRESS_INFORMATION: 'Address Information',
  PASSPORT_VISA_INFORMATION: 'Passport and Visa Information',
  LANGUAGE_TEST_SCORE: 'Language Test Score',
  INTERESTED_SERVICES: 'Interested Services',
  COMMENTS: 'Comments',
  PASSPORT_NUMBER: 'Passport Number',
}