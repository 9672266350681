import React, { Suspense, useState } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import Cookies from 'js-cookie';
import { COOKIE_ACCESS } from '@moxie/constants';
import {
  DashboardLayout,
  SessionRefreshModal,
} from '@shared-components/modules';
import { Spin } from 'antd';

const ProtectedRoute = ({
  component: Component,
  ...rest
}: RouteProps | any): any => {
  const [access] = useState(Cookies.get(`${COOKIE_ACCESS}`));
  return (
    <Route
      {...rest}
      render={(props) =>
        !access ? (
          (window.location.href = '/')
        ) : (
          <DashboardLayout>
            <Suspense
              fallback={
                <div className="page-load">
                  <Spin />
                </div>
              }
            >
              <SessionRefreshModal />
              <Component {...props} />
            </Suspense>
          </DashboardLayout>
        )
      }
    />
  );
};

export { ProtectedRoute };
