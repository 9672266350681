import React, { FC } from 'react';
import { Input as AntInput, InputProps, Select } from 'antd';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import ReactCountryFlag from 'react-country-flag';
import { COUNTRIES } from '@moxie/constants';
import {
  Country,
  IGetPopupContainer,
  IPhoneInput,
  IPhoneSelectBefore,
} from '@shared-components/models';

const { Option } = Select;

const selectBefore = ({
  data,
  onChange,
  disabled,
  onSearch,
  countries,
}: IPhoneSelectBefore) => {
  return (
    <div id="lead_phone_input" className="relative">
      <Select
        className="country_select"
        showSearch
        defaultValue={data}
        value={data}
        disabled={disabled}
        onChange={onChange}
        onSearch={onSearch}
        getPopupContainer={(): IGetPopupContainer =>
          document.getElementById('lead_phone_input')
        }
      >
        {countries.map((item) => {
          return (
            <Option
              value={item.dial_code}
              key={item.code}
              className="country_select"
            >
              <ReactCountryFlag countryCode={item.code} svg />
              <span className="padding-1">{item.dial_code}</span>
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

const Input: FC<InputProps> = ({ type, ...props }: InputProps) => {
  return <AntInput type={type} {...props} />;
};

const PhoneInput: FC<IPhoneInput> = ({
  type,
  dialCode,
  onChangeDialCode,
  filterCountryCodeOnSearch = false,
  ...props
}: IPhoneInput) => {
  const [countries, setCountries] = React.useState<Country[]>(COUNTRIES);

  const onSearch = (val: string) => {
    if (filterCountryCodeOnSearch) {
      setCountries(
        COUNTRIES.filter((country) => {
          return country.name.toLowerCase().includes(val.toLowerCase());
        })
      );
    }
  };

  return (
    <AntInput
      type={type}
      {...props}
      addonBefore={selectBefore({
        data: dialCode,
        onChange: onChangeDialCode,
        disabled: props?.disabled,
        countries,
        onSearch,
      })}
    />
  );
};

export { Input, PhoneInput };
